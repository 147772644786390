import type {
    Block,
    Flat,
    ModalData,
} from "@/components/smartComponents/ProjectsRender/Parts/projectsRender.interface";

import { ApRange } from "@/components/smartComponents/ComplexFilter/complexFilter.interface";
import type { ApartmentState } from "@/store/ts/interfaces/apartmentState.interface";
import type { Floor } from "@/store/ts/interfaces/apartmentState.interface";
import { defineStore } from "pinia";
import fn from "@/helpers/func";
import get from "lodash-es/get";
import { id } from "date-fns/locale";

export const useApartmentStore = defineStore({
    id: "apartmentStore",
    state: (): ApartmentState => ({
        blocks: null,
        blockFlats: null,
        blockFloors: null,
        maxFloors: null,
        allFlats: null,
        allTypes: null,
        floorFlats: null,
        selectedBlock: null,
        selectedFloor: null,
        selectedFloorTypes: null,
        availableRoomValues: [],
        projectId: null,
        blockNumber: null,
        floorNumber: null,
        selectedBlockNumber: '',
        selectedFloorNumber: 0,
        selectedApNumber: null,
        isApartmentOpenedFromRow: false,
        svgRenderType: null,
        availableBlockIds: [],
        availableFloorNumbers: [],
        availableArea: [],
        availablePrice: [],
        availableRoom: null,
        filteredFlatList: null,
        filteredData: {
            roomCount: 4,
            selectedRoom: null,
            selectedBuild: null,
            selectedFloor: null,
            selectedArea: [],
            selectedPrice: null,
        },
        blockTitle: null
    }),

    actions: {
        setArea(e: string, apRange: ApRange[]): void {
            const arr = apRange
                ?.filter((el) => el?.slug?.includes("area"))
                .find((areaEl) => Number(get(areaEl, "id")) === Number(e));

            this.filteredData.selectedArea = [
                Number(get(arr, "title")),
                Number(get(arr, "title2")),
            ];
        },
        setPrice(e: string, apRange: ApRange[]): void {
            const arr = apRange?.filter((el) => el?.slug?.includes("price"));
            const filteredArr = arr.find(
                (priceEl) => Number(get(priceEl, "id")) === Number(e)
            );
            this.filteredData.selectedPrice = [
                Number(get(filteredArr, "title")),
                Number(get(filteredArr, "title2")),
            ];
        },
        async getAllFlats(): Promise<void> {
            const block_id: number[] = [];
            this.blocks.forEach((block) => {
                block_id.push(block.id);
            });
            const { data } = await fn.postData("dev/getFlats", {
                project_id: [this.projectId],
                block_id,
            });

            this.blocks.forEach((block: Block) => {
                block.sold_flats_length = 0;
                block.flats_length = 0;
            });

            if (!data?.list?.length) {
                this.allFlats = null;
            } else {
                const activeFlats = data?.list?.filter((flat: Flat) => {
                    if (
                        flat?.development_types_rooms &&
                        !flat?.conf?.includes("sold") &&
                        !this.availableRoomValues?.includes(
                            flat?.development_types_rooms
                        )
                    ) {
                        if (flat?.development_types_rooms >= 4) {
                            this.availableRoomValues.push(4);
                        } else {
                            this.availableRoomValues.push(
                                flat?.development_types_rooms
                            );
                        }
                    }
                    const isSold = flat?.conf?.includes("sold");
                    const flatBlock: Block = this.blocks.find(
                        (block: Block) => block.id === flat.block_id
                    );

                    flatBlock.flats_length += 1;
                    if (isSold) {
                        flatBlock.sold_flats_length += 1;
                    }
                    return !flat?.conf?.includes("sold");
                });
                this.blocks.forEach((block: Block) => {
                    if (block.sold_flats_length === block.flats_length) {
                        block.sold = true;
                    }
                });
                this.allFlats = activeFlats;
            }
        },

        async getBlockFlats(): Promise<void> {
            if (!this.selectedBlock?.id) return;
            const { data } = await fn.postData("dev/getFlats", {
                project_id: [this.projectId],
                block_id: [this.selectedBlock?.id],
            });

            if (!data?.list?.length) {
                this.blockFlats = null;
            } else {
                this.blockFlats = data?.list;
            }
        },
        async getBlockFloors(): Promise<void> {
            const { data } = await fn.postData("dev/getFloors", {
                project_id: [this.projectId],
                block_id: [this.selectedBlock?.id],
                limit: 70
            });

            if (!data?.list?.length) return;
            data?.list.forEach((floor: Floor) => {
                if (!this.blockFlats) return;
                const floorAps = this.blockFlats?.filter(
                    (flat) => flat?.floor_number === floor?.floor_number
                );
                const soldAps = floorAps.filter((flat) =>
                    flat?.conf?.includes("sold")
                );

                return (floor.sold = floorAps.length === soldAps.length);
            });
            this.blockFloors = data?.list.sort(
                (a: Floor, b: Floor) => a?.floor_number - b?.floor_number
            );
        },

        async getSelectedFloor(): Promise<void> {
            // if (this.isApartmentOpenedFromRow) return;
            if (!this.blockFloors?.length) {
                this.selectedFloor = null;
            } else {
                const getSelectedFloor = this.blockFloors?.filter(
                    (floor: Floor) => floor.floor_number === this.floorNumber
                );

                if (!getSelectedFloor.length) {
                    this.selectedFloor = null;
                } else {
                    this.selectedFloor = getSelectedFloor[0];
                }
            }
        },

        async getFloorFlats(): Promise<void> {
            if (!this.selectedBlock?.id) return;

            const { data } = await fn.postData("dev/getFlats", {
                project_id: [this.projectId],
                block_id: [this.selectedBlock?.id],
                floor_number: [this.selectedFloor?.floor_number],
            });
            if (!data?.list?.length) {
                this.floorFlats = null;
            } else {
                this.floorFlats = data?.list;
            }
        },

        async getFloorTypes(): Promise<void> {
            const { data } = await fn.postData("dev/getTypes", {
                project_id: [this.projectId],
                block_id: [this.selectedBlock?.id],
            });
            if (!data?.list?.length) {
                this.selectedFloorTypes = null;
            } else {
                return (this.selectedFloorTypes = data?.list);
            }
        },

        async getAllTypes(): Promise<void> {
            const { data } = await fn.postData("dev/getTypes", {
                project_id: [this.projectId],
            });

            this.allTypes = data?.list;
        },
    },
});
