<template>
    <svg
        width="84"
        height="83"
        viewBox="0 0 84 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M55.5668 51.6479L45.3621 41.4433L55.9069 30.8985C56.373 30.4324 56.6408 29.8062 56.6515 29.1577C56.6621 28.5091 56.4147 27.8914 55.9636 27.4403C55.5125 26.9892 54.8948 26.7418 54.2462 26.7524C53.5977 26.7631 52.9715 27.0309 52.5054 27.497L41.9606 38.0418L31.7559 27.8371C31.3049 27.3861 30.6871 27.1386 30.0386 27.1493C29.39 27.1599 28.7638 27.4277 28.2977 27.8938C27.8316 28.3599 27.5638 28.9861 27.5531 29.6347C27.5425 30.2832 27.7899 30.901 28.241 31.3521L38.4456 41.5567L27.9009 52.1015C27.4348 52.5676 27.1669 53.1938 27.1563 53.8423C27.1457 54.4909 27.3931 55.1086 27.8442 55.5597C28.2952 56.0108 28.913 56.2582 29.5615 56.2476C30.2101 56.237 30.8363 55.9691 31.3024 55.503L41.8472 44.9582L52.0518 55.1629C52.5029 55.614 53.1207 55.8614 53.7692 55.8508C54.4177 55.8401 55.044 55.5723 55.5101 55.1062C55.9762 54.6401 56.244 54.0139 56.2546 53.3653C56.2653 52.7168 56.0178 52.099 55.5668 51.6479Z"
            fill="#FFEFE1"
        />
    </svg>
</template>
